
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  	var doingajax = false;

  	function isOnScreen(el){

	    var win = $(window);

	    var viewport = {
	        top : win.scrollTop(),
	        left : win.scrollLeft()
	    };
	    viewport.right = viewport.left + win.width();
	    viewport.bottom = viewport.top + win.height();

	    var bounds = el.offset();
	    bounds.right = bounds.left + el.outerWidth();
	    bounds.bottom = bounds.top + el.outerHeight();

	    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

	}

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      jQuery('body').on('click', '.addlike', function(e) {
		  	if( doingajax === true || jQuery(e.target).hasClass('liked')) {
		  		return;
			}
			doingajax = true;
			post_id = jQuery(this).attr('data-id');
			jQuery.ajax({
				url: '/wp-admin/admin-ajax.php?action=addLike&post_id='+post_id,
				success: function(response) {
					if(response !== 'posted') {
						jQuery('.quantity-' + post_id).empty();
						jQuery('.quantity-' + post_id).append(response);
						jQuery('.love-hover-' + post_id).addClass("liked");
					}
					doingajax = false;
				}
			});
		  });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

		      var post_id = 0;

		      var order = 'date';

		      var order_invert = false;


		      var offset = 16;

		      var bk_offset = 16;


		      var tl = new TimelineLite();
		      tl.to( '#create-opinion', 0.2, { x: "0px", ease: Power1.easeOut } );
		      tl.pause();
		      jQuery('.flat-round-button').click(function() {

				if( jQuery(this).data('target') !== '#login' ) {
					tl.play();
				}

		      });
		      jQuery('.overlay-close').click(function() {
			     tl.reverse();
		      });

		      var tl_read = new TimelineLite();
		      tl_read.to( '#news-content', 0.2, { x: "-100%", ease: Power1.easeOut } );
		      tl_read.to( '#read-opinion', 0.2, { x: "0%", ease: Power1.easeOut }, 0 );
		      tl_read.pause();

	      	jQuery('.content-holder').on( 'click', '.community-entry, .news-entry', function( e ) {
		      	if( e.target.localName === 'svg' && jQuery(e.target).parent().hasClass('addlike')) {
			      	if( doingajax === true || jQuery(e.target).parent('span').hasClass('liked')) {
				      	return;
			      	}
			      	doingajax = true;
			     	post_id = jQuery(e.target).parent('span').attr('data-id');
					jQuery.ajax({
						url: '/wp-admin/admin-ajax.php?action=addLike&post_id='+post_id,
						success: function(response) {
							if(response !== 'posted') {
								jQuery('.quantity-' + post_id).empty();
								jQuery('.quantity-' + post_id).append(response);
								jQuery('.love-hover-' + post_id).addClass("liked");
							}
							doingajax = false;
						}
					});
			      return;
		      }

		      tl_read.play();

		      post_id = jQuery(this).data('post-id');
		      jQuery.ajax({
				  url: projectsAjax.url,
				  data: { action: 'loadPost', post_id: post_id },
				  success: function( response ) {
					  jQuery('.ajax-post').html( response.response );
				  },
				  dataType: 'json'
			  });

	      });

	      // Attach handler to dynamic AJAX Load
	      jQuery('.content-holder').on( 'click', '.overlay-read-close', function() {
		  tl_read.reverse();

		  jQuery('#read-opinion').css('transform','1,0,0'+jQuery('#news-entry').width()+',0,0');
			   jQuery( 'body' ).removeClass('entry-opened');
	      });


		  	function onResize() {
			  if ( jQuery( window ).width() <= 991 ){

				   var map_holder_height = jQuery( 'body.page-template-template-startseite .map-holder' ).outerHeight();
				   var news_content_height = jQuery( 'body.page-template-template-startseite .content-holder#news-content' ).outerHeight();


			   		jQuery( '.navbar-toggle, .wrap, .open_search_field' ).on('click', function(){
			   			if( jQuery( 'body' ).hasClass('modal-open') === true){
			   				jQuery( '.close-logon-modal' ).click();
						}
					});

				   jQuery( 'li.new_opinion_address_field input' ).prop('required',true);
				   jQuery( 'li.new_opinion_address_field' ).addClass( 'gfield_contains_required' );


				    jQuery( '.content-holder .news-entry, .content-holder .community-entry' ).on( 'click',  function() {
						jQuery( 'body' ).addClass( 'entry-opened' );
					  	jQuery( 'body' ).scrollTop( 0 );
			  		});

				    if ( jQuery( 'body' ).hasClass( 'logged-in' ) === true ){
					    jQuery( '.flat-round-button' ).on( 'click',  function() {
						    var headerheight = jQuery( 'header' ).height();
 							jQuery( 'body' ).scrollTop( map_holder_height + headerheight );
							jQuery( 'body' ).addClass( 'idea-form-openend' );
						});

						jQuery('.close.overlay-close').on('click', function(){
							jQuery('body').removeClass( 'idea-form-openend' );
						});
					}

			  }

			}

			onResize();

			jQuery(window).on('resize', function(){
					onResize();
			});

			jQuery('.ajax-filter').click( function() {

		      var self = this;
		      order = jQuery(this).data('order');
			  order_invert = jQuery(this).data('order-invert');
		      jQuery.ajax({
				  url: projectsAjax.url,
				  data: { action: 'load_opinions', order: order, order_invert : order_invert},
				  success: function( response ) {
					  	TweenMax.to( jQuery('.community-entry'), 0.56 , { x: "-25%", autoAlpha: 0, force3D:true, ease: Sine.easeOut, onComplete: function() {
							jQuery('.community-entries').html( response.response );
							TweenMax.fromTo( jQuery('.community-entry-offcanvas'), 0.56 , { x: "25%", autoAlpha: 0, force3D:true }, { x: "0%", autoAlpha: 1, force3D: true, ease: Sine.easeOut, onComplete: function() {
								jQuery('.community-entry').removeClass('community-entry-offcanvas');
							}});

							// Add active class to filter
							jQuery('.filter-label').removeClass('active');
							jQuery(self).siblings('.filter-label').addClass('active');

							jQuery('.ajax-filter').removeClass('active');
							jQuery(self).addClass('active');

							offset = bk_offset;

							jQuery('.loadmore').html("Mehr Beiträge werden geladen<span class=\"dots\">.....</span>");
						}});

				  },
				  dataType: 'json'
			  });
	    });

	      // Load more opinions
	    jQuery('#news-content').on('scroll', function() {
		     jQuery('.loadmore').css( 'display', 'block' );
		     if ( isOnScreen( jQuery('.loadmore') ) ) {
			     if( doingajax === true) {
				     return;
			     }
			     doingajax = true;
			     jQuery.ajax({
				    url:  projectsAjax.url,
				    data: { action: 'load_opinions', order: order, order_invert : order_invert, offset: offset},
				    success: function( response ) {
					    jQuery('.community-entries').append( response.response );

						jQuery('.community-entry').removeClass('community-entry-offcanvas');
						doingajax = false;
						offset = offset + 10;
						if( response.response === "" ) {
							jQuery('.loadmore').text("Keine weiteren Beiträge gefunden");
						}
				    },
				    dataType: 'json'
			     });
		     }
	   });

		// close_signup_overlay on click
	    jQuery( '.close-logon-modal' ).on('click', function() {
			jQuery( '.modal' ).modal("hide");
		});


        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // set minheights
  	if ( jQuery('body').hasClass( 'page-template-template-glossary' ) !== true ){
	 	var sidebar = jQuery('aside.sidebar');
	 	var main = jQuery('main.main');
		var sidebar_height = sidebar.outerHeight();
		var main_height = main.outerHeight();

		if ( main_height < sidebar_height ){
			if( jQuery('.sidebar:visible').length !== 0 ) {
				jQuery('.main').css("min-height", sidebar.outerHeight());
			}
		} else{
			if( jQuery('.main:visible').length !== 0 ) {
				jQuery('aside.sidebar').css("min-height", main.outerHeight());
			}
		}
	}

	jQuery('.navbar-toggle').on('click', function() {
		if(jQuery('.navbar-collapse').hasClass('open') === true) {
			jQuery('.navbar-collapse, .navbar-toggle').add('.navbar_buttons').removeClass('open');
			if( jQuery( 'body' ).hasClass('modal-open') ){
				jQuery('.user-image .btn').click();
			}
			jQuery('.navbar-default').removeClass( 'open' );


		}
		else {
			jQuery('.navbar-collapse, .navbar-toggle').add('.navbar_buttons').addClass('open');
			jQuery('.navbar-default').addClass( 'open' );
		}
	});

	jQuery('.menu-primary-navigation-container').on('hover', function() {
		if (jQuery('body').hasClass('navi-hovered') !== true && jQuery(window).width() > 1199 ){
			jQuery('body').addClass('navi-hovered');
		}
	});

	jQuery('.menu-primary-navigation-container > li').on('click', function() {
		if (jQuery('body').hasClass('navi-hovered') !== true ){
			jQuery('body').addClass('navi-hovered');
		}
	});


  	// show correct glossary entry and hide intro desc
	$( '.glossary-word' ).on( 'click', function(){
		var glossar_entry = $( this ).data( 'glossary-entry' );

		jQuery( '.glossary_intro_desc').addClass( 'hidden' );

		if( jQuery(window).width() > 1200 ) {
			TweenMax.to( window, 0.5, {scrollTo:81} );
		} else if( jQuery(window).width() > 768 ) {
			jQuery("html, body").animate({ scrollTop: "0px" });
		}

		jQuery( '.content-glossary[data-glossary-entry="' + glossar_entry + '"]' ).addClass( 'show_glossar_entry' );
		jQuery( '.content-glossary[data-glossary-entry="' + glossar_entry + '"]' ).removeClass( 'hide_glossar_entry' );

		jQuery( '.content-glossary[data-glossary-entry!="' + glossar_entry + '"]' ).addClass( 'hide_glossar_entry' );
		jQuery( '.content-glossary[data-glossary-entry!="' + glossar_entry + '"]' ).removeClass( 'show_glossar_entry' );
	});


	$( 'body.page-template-template-faq .create_faq_btn' ).on( 'click', function(){
		$( '.new-faq-form' ).addClass( 'open' );
	});

	$( 'body.page-template-template-faq .close-form' ).on( 'click', function(){
		$( '.new-faq-form' ).removeClass( 'open' );
	});


	jQuery('.close.overlay-close').on('click', function() {
		if (jQuery('body').hasClass('idea-form-openend')){
			jQuery('body.page-template-template-startseite').removeClass('idea-form-openend');
		}
	});

	//close button FAQ
	jQuery('.sidebar-post.active .close-button').on( 'click', function(){
		jQuery(this).parent().removeClass('active');
	});


	jQuery('.menu-primary-navigation-container').on('mouseleave', function() {
		if (jQuery('body').hasClass('navi-hovered') === true ){
			jQuery('body').removeClass('navi-hovered');
		}
	});

	jQuery('.glossary-list-entry.glossary-word').click(function (){
		if(jQuery(window).width() < 768){
			var glossaryEntry = jQuery(this).attr('data-glossary-entry');
			var html = jQuery('.content-glossary[data-glossary-entry=' + glossaryEntry + ']').html();
			jQuery(this).parent().append(html);
			var glossary_pos = jQuery(this).parent();
			jQuery('.close.modal-close').click(function(){
				jQuery('.glossary-container', glossary_pos).remove();
			});
		}
	});


	jQuery('.close.modal-close').click(function(){
		jQuery('.modal-overlay').addClass('modal-closed').removeClass( 'opened' );
	});
	jQuery(window).resize(function(){
		if (jQuery(window).width() <= 768){
			if (jQuery('.modal-overlay').hasClass('modal-closed')){
				jQuery('.modal-overlay.modal-closed').removeClass('modal-closed');
				jQuery('.modal-overlay').addClass('opened');
			}
		}
	});

	jQuery('.open-modal').click(function(){
		jQuery('.modal-overlay').removeClass('modal-closed').addClass( 'opened' );

	});


	jQuery('.user-image .btn').on('click', function(){
		if(jQuery('.navbar-collapse').hasClass('open') === true) {
			jQuery('.navbar-toggle').click();
		}
	});
	// close navi if search field is clicked
	jQuery('header .mobile_open_search_field').on('click', function(){
		jQuery('.package-container').addClass('open_search_container');
		jQuery('.collapse.open.navbar-collapse.in').removeClass('open');
		jQuery('.collapse.navbar-collapse.in').removeClass('in');
		jQuery('.navbar-toggle.collapsed.open').removeClass('open');

	});

	// close search overlay on click
	jQuery( '.package-container .search-overlay' ).on('click', function(){
		jQuery( '.package-container').removeClass( 'open_search_container' );
	});

	//Hide comment when submit success
	jQuery('.gform_button[type="submit"]').click( function() {
		var interval = setInterval(function() {
			// Do something every 5 seconds
			if( jQuery('.gform_confirmation_message').length > 0 ){
				jQuery('.check_comments').hide();
				clearInterval(interval);
			}
		}, 100);
		setTimeout(function(){ clearInterval(interval); }, 10000);
	});

	jQuery('header .open_search_field, header .search-submit').on('focus', function(){
		jQuery('header .search-field').focus();
	});

	jQuery('header .search-field').on('focus', function(){
		jQuery('.container-fluid, .container.wrap, .search-overlay, .navbar-brand').click(function(){
			jQuery('header .search-field').blur();
		});
	});

	//Header scroll on mobile
	jQuery(window).scroll(function(){
		if(jQuery(document).scrollTop() >= 66){
			jQuery('.header-container .brand-area').addClass('hidden-brand-area');
			} else {
				jQuery('.header-container .brand-area').removeClass('hidden-brand-area');
			}

	});

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).on('click', '[data-toggle="link"]', function (e) {
    window.open('https://app.e-mitwirkung.ch/thun/login','_blank');
  });


})(jQuery); // Fully reference jQuery after this point.
